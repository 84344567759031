import ReactLoader from 'react-loaders'
export default function Loader() {
    return <div className='loader-wrap'>
        <ReactLoader type="line-scale" active />
    </div>;
}

export function NoData() {
    return <span className='h6 error'>No data available!</span>;
}

export function Error() {
    return <span className='h6 error text-danger'>Some Error Occured! Please refresh.</span>;
}

export function Apt(props) {
    const { state } = props;
    if (state.loading) {
        return <Loader />;
    } else if (state.error) {
        return <Error />;
    } else if (!state.value?.length) {
        return <NoData />;
    } else {
        return <>{props.children}</>;
    }
}