import { postHelper } from "../helper";
import EmbeddedPost from "./EmbeddedPost";
import { CategoryTags, LikeButton, SinglePost } from "./Widget";
import { ShareButtons } from "./ShareButtons";
import { Link } from "react-router-dom";

export default function QuotePost(props) {
    const post = postHelper(props.post);
    if (post.embedCode) {
        return <EmbeddedPost {...props} />
    }
    const entityPage = props.entityPage;
    const colMdWidth = entityPage ? 6 : 4;
    const colSmWidth = props.colSm ? 9 : 6;
    const colLgWidth = entityPage ? 5 : 3;

    let extraMarg;
    const quoteClass = entityPage ? `quote-post col-lg-${colLgWidth} col-md-${colMdWidth} col-sm-${colSmWidth} col-xs-12` : `quote-post col-12`;
    let quoteStory = <></>;
    if (entityPage && post.story) {
        quoteStory = (
            <>
                <div className={quoteClass}>
                    <h1>Story</h1>
                    <pre dangerouslySetInnerHTML={{ __html: post.story }} />
                </div>
            </>
        );
        extraMarg = <div className="col-lg-1"></div>;
    }
    return <>{extraMarg}<div className={quoteClass}>
        <div className="newspaper-x-blog-post-layout-c">
            <div className="newspaper-x-image">
                <Link to={post.detailLink()}>
                    <Image post={post} />
                </Link>
            </div>
                <div className="row">
                    <div className="col-7">
                    <span className="newspaper-x-date">{post.dateFormatted()}</span>
                    
                    </div>
                    <div className="col-5 shares">
                        <ShareButtons entityPage={entityPage} post={post} />
                    </div>
                </div>
            <div className="newspaper-x-content">
                {entityPage ? <SinglePost post={post} /> : <Link to={post.detailLink()}><pre style={{ textAlign: 'center' }}>{post.text}</pre></Link>}
            </div>
                <div className="row">
                    <div className="col-12">
                    <CategoryTags post={post} />
                    </div>
                </div>
        </div>
    </div>
        {quoteStory}
        {extraMarg}
    </>
}

function Image({ post }) {
    let style = {};
    return <img width="416" height="400"
        src={post.banner()}
        className="attachment-newspaper-x-recent-post-big size-newspaper-x-recent-post-big wp-post-image"
        alt={post.headline() || post.text} style={style} />;
}
