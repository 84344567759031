import { postHelper } from "../helper";

const loader=`<div class="loader-wrap"><div class="loader loader-undefined loader-active"><div class="loader-inner line-scale"><div></div><div></div><div></div><div></div><div></div></div></div></div>`;
export default function EmbeddedPost(props) {
    const post = postHelper(props.post);
    const colMdWidth = props.colMd ?? 4;
    const colLgWidth = props.colLg ?? 3;
    const colSmWidth = props.colSm ?? 6;
    return <div className={`col-lg-${colLgWidth} col-md-${colMdWidth} col-sm-${colSmWidth} col-xs-12`}>
        <div className="newspaper-x-blog-post-layout-c">
            {
                (settings[post.type] ?? settings.default)(post)
            }
        </div>
    </div>
}

const settings = {
    facebook(post) {
        return <div className="fb-post" data-lazy={true} data-show-text={true} data-href={post.detailLink()}>
            <div divdangerouslySetInnerHTML={{__html: loader}}/>
        </div>
    },
    default(post) {
        const embedCode = post.embedCode;
        return <div dangerouslySetInnerHTML={{ __html: embedCode }}/>;
    }
};

