import { Link } from "react-router-dom";
import { postHelper } from "../helper";
import EmbeddedPost from "./EmbeddedPost";

export default function WriterPost(props) {
    const post = postHelper(props.post);
    if(post.embedCode) {
        return <EmbeddedPost {...props}/>
    }

    return <div className="col-md-4 col-xs-6">
        <div className="newspaper-x-blog-post-layout-b">
            <div className="newspaper-x-image">
                <Link to={post.detailLink()}>
                    <img width="550" height="360"
                        src={post.banner()}
                        className="attachment-newspaper-x-recent-post-big size-newspaper-x-recent-post-big wp-post-image"
                        alt={post.headline()} />
                </Link>
            </div>
            <div className="newspaper-x-title">
                <h4>
                    <Link to={post.detailLink()}> {post.headline()} </Link>
                </h4>
            </div>
            <span className="newspaper-x-author">
                <Link to={post.author.link()}>{post.author.name()}</Link>
            </span>
            <span className="newspaper-x-date">{post.dateFormatted()}</span>
            <div className="newspaper-x-content">
                {post.detailShort()}
                <Link className="dot3-link" to={post.detailLink()}/>
            </div>
        </div>
    </div>;
}