export default function TopHeader(props) {
    const { me } = props;
    return (
        <div className="top-header">
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <section className="newspaper-x-news-ticker">
                            <span className="newspaper-x-module-title">
                            <img src="/assets/img/me-small.jpg" style={{width: "50px"}} className="custom-logo" alt="me" /> 
                            </span>
                            <span>Welcome to my blog...</span>
                        </section>
                    </div>
                    <div className="col-4" style={{ height: "42px" }}>
                        <div className="top-header-icons">
                            <div id="menu-social" className="menu pull-right">
                                <ul id="menu-social-items" className="menu-items">
                                    <li id="menu-item-111"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-111">
                                        <a target="blank" href={me.facebook}><span className="screen-reader-text">Facebook</span></a>
                                    </li>
                                    <li id="menu-item-112"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-113">
                                        <a target="blank" href={me.twitter}><span className="screen-reader-text">Twitter</span></a>
                                    </li>
                                    <li id="menu-item-113"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-114">
                                        <a target="blank" href={me.yourquote}><span className="screen-reader-text">Yourquote</span></a>
                                    </li>
                                    <li id="menu-item-114"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-115">
                                        <a target="blank" href={me.instagram}><span className="screen-reader-text">Instagram</span></a>
                                    </li>
                                    <li id="menu-item-115"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-116">
                                        <a target="blank" href={me.github}><span className="screen-reader-text">Github</span></a>
                                    </li>
                                    <li id="menu-item-116"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-117">
                                        <a target="blank" href={me.linkedin}><span className="screen-reader-text">LinkedIn</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}