import { postHelper } from "../helper";
import EmbeddedPost from "./EmbeddedPost";
import { CategoryTags, LikeButton, SinglePost } from "./Widget";
import { ShareButtons } from "./ShareButtons";
import { Link } from "react-router-dom";

export default function BlogPost(props) {
    const post = postHelper(props.post);
    if (post.embedCode) {
        return <EmbeddedPost {...props} />
    }
    const entityPage = props.entityPage;
    const colMdWidth = props.colMd ?? 4;
    const colLgWidth = props.colLg ?? 3;
    const colSmWidth = props.colSm ?? 6;
    const postClass = entityPage ? `col-lg-${colLgWidth} col-md-${colMdWidth} col-sm-${colSmWidth} col-xs-12` : `post-post col-12`;

    return <div className={postClass}>
        <div className="newspaper-x-blog-post-layout-c">
            <div className="newspaper-x-image newspaper-blog-image">
            <Link to={!entityPage && post.detailLink()}>
                    <img width="550" height="360"
                        src={post.banner()}
                        className="attachment-newspaper-x-recent-post-big size-newspaper-x-recent-post-big wp-post-image"
                        alt={post.headline()} />
            </Link>
            </div>
            <div className={entityPage && "row"}>
                <div className={entityPage && "col-9 col-sm-10 col-md-10 col-lg-11"}>
                    <div className="newspaper-x-title">
                        <h4>
                            <Link to={!entityPage && post.detailLink()}> {post.headline()} </Link>
                        </h4>
                    </div>
                    <CategoryTags post={post} />
                    <span className="newspaper-x-date">{post.dateFormatted()}</span>
                </div>
                {
                    entityPage && <div className="col-3 col-sm-2 col-md-2 col-lg-1">
                        <LikeButton post={post} />
                        <ShareButtons post={post}/>
                    </div>
                }
            </div> 
            <div className="newspaper-x-content">
                {entityPage ?
                    <SinglePost post={post}/> : <>
                        {post.detailShort()}
                        <Link className="dot3-link" to={post.detailLink()} />
                    </>}
            </div>
        </div>
    </div>
}


