import NewspaperPost from "./NewspaperPost";

export default function NewspaperPosts(props) {
    const postsState = props.posts;
    if(postsState.loading) {
        return <></>;
    } 
    const posts = ( postsState.value || []).slice(0, 3);
    return <div className="newspaper-x-header-widget-area">
        <div id="newspaper_x_header_module-2" className="widget newspaper_x_widgets container">
            <div className="newspaper-x-recent-posts row">
                <ul> {posts.map((post, i) =>
                    <NewspaperPost key={i} post={post} i={i} />
                )} </ul>
            </div>
        </div>
    </div>;
}