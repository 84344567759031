import { useRef } from "react";
import config from "../../config.json";
export function ShareButtons({ entityPage, post }) {
    const ui = useRef(null);
    if(!ui.current) ui.current = (() => {
        let Div = 'div';
        if (!entityPage && post.type === 'quote') {
            Div = props =>
                <div className="a2a_kit a2a_default_style" data-a2a-url={config.base + post.shortLink()} data-a2a-title={post.text} {...props}>
                    <i className="fa fa-share-alt pull-left" aria-hidden="true"></i>
                    {props.children}
                </div>
        }
        return <Div className="a2a_kit a2a_default_style">
            <a className="a2a_button_facebook" />
            <a className="a2a_button_whatsapp" />
            <a className="a2a_dd" href="https://www.addtoany.com/share" />
        </Div>
    })();
    console.log(ui)
    return ui.current;
}

