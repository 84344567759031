/* eslint-disable react-hooks/rules-of-hooks */
import BrandingNavbar from './BrandingNavbar';
import TopHeader from './TopHeader';
import NewspaperPosts from './Contents/NewspaperPosts';
import Sitebody from './Contents/Sitebody/Sitebody';
import Footer from './Contents/Footer';
import data from "./data/me.json";
import { Routes, Route, useParams } from "react-router-dom";
import { useCustomEventListener } from 'react-custom-events';
import { useEffect, useState } from 'react';
import { apiHelper } from './api/apiHelper';
import GoldenButton from './GoldenButton';

const callApis = apiHelper();
let r1 = callApis.init();
function App() {
  const [results, setResults] = useState(r1);
  useCustomEventListener('my-event', _results => {
    setResults({ ..._results });
  });
  useEffect(() => {
    callApis.exec();
  }, []);
  return (
    <>
      <GoldenButton />
      <TopHeader me={data.me} />
      <BrandingNavbar items={results} />
      <Routes>
        <Route path="/" element={<HomePage results={results} />} />
        <Route path='/portfolio' element={<ReloadPage />} />
        <Route path="/:page" element={<Page results={results} />} />
        <Route path="/:page/:entityRef" element={<Page results={results} />} />
      </Routes>
      <Footer me={data.me} />
    </>
  );
}
export default App;

function HomePage({ results }) {
  return <><NewspaperPosts posts={results.posts} />
    <Sitebody data={results} />
  </>
}

function Page(props) {
  const params = useParams();
  return <Sitebody data={props.results} options={params} />
}

function ReloadPage() {
  window.location.reload();
}
