import { getTitle, lim } from "../helper";
import { Apt } from "../Loader";
import config from "../../config.json";
import { Link } from "react-router-dom";
import { postHelper } from "../helper";

export function Widget(props) {
    const { iter, page } = props;
    let { title } = props;
    let limit;
    if (!page && iter) {
        title = getTitle(iter.state, title);
        limit = iter.state.homeItems ?? config.defaultHomepageItems;
    }
    let titleBar, viewMoreButton;
    if (!page) {
        titleBar = <h3 className="widget-title"><span className="title-with-loader">{title}<Apt state={iter.state} /></span></h3>;
        if (iter?.state?.value?.length) {
            viewMoreButton = <Link className="btn-blog" to={`/${iter.state.key}`}>All {title} »</Link>;
        }
    } else {
        titleBar = <Apt state={iter.state} />;
    }


    return <div id={props.id} className="widget newspaper_x_widgets">
        {titleBar}
        <div className={`row ${props.rowClass}`}>
            {iter ? (iter.state.loading ? <></> : <><Iter limit={limit} posts={iter.state.value} Component={iter.Component} {...iter} />{viewMoreButton}</>) : props.children}
        </div>
    </div>;
}
export function Iter(props) {
    const { i: I, Component: IterComponent, limit, componentProps = {}, top } = props;
    let { posts } = props;
    if (!posts) {
        return <></>;
    }
    if (limit) {
        posts = posts.slice(0, limit);
    }

    if (top) {
        return <ul>{posts.map((post, i) => <IterComponent key={i} post={post} i={I ?? i} {...componentProps} />)}</ul>;
    }
    const postsColWise = [];
    posts.forEach((post, j) => {
        const i = j % 4;
        const col = postsColWise[i] = postsColWise[i] ?? [];
        col.push(post);
    });
    const len = postsColWise.length;
    const colLg = 12 / len;
    const colMd = 12 / (len - 1 < 1 ? 1 : len - 1);
    const colSm = 12 / (len - 2 < 1 ? 1 : len - 2);

    const { entityPage } = componentProps;
    const postCol = entityPage ? '' : 'postCol';
    return <div className="container">
        <div className="row">
            {postsColWise.map(postRows => (
                <div className={`col-lg-${colLg} col-md-${colMd} col-sm-${colSm} col-12 ${postCol}`}>
                    {postRows.map((post, i) => <IterComponent key={i} post={post} i={I ?? i} {...componentProps} />)}
                </div>
            ))}
        </div>
    </div>;
}
export function value(state, def = []) {
    return state.loading ? def : state.value ?? [];
}

export function CategoryTags(props) {
    const { post } = props;
    return <div className="tag-list"><span>
        {
            post.categories.map(cat =>
                <span key={cat} className="newspaper-x-category">
                <Link to={post.linkToCategory(cat)}>{'#' + postHelper.ucfirst(cat)}</Link>
                </span>
            )
        }
    </span></div>;
}

export function LikeButton({ post }) {
    return <span className="likebtn-wrapper" data-theme="likebtn" data-white_label="true" data-dislike_enabled="false" data-counter_frmt="km" data-popup_disabled="true" data-popup_position="bottom"></span>
}

export function SinglePost({ post }) {
    return <pre className="single-post">
        <div className="entry-content">
            {
                post.html ? <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    : <p>{post.text}</p>
            }
        </div>
    </pre>;
}
