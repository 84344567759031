import { getArray, getTitle, postHelper } from "./Contents/helper";
import config from "./config.json";
import {
    Link,
    useMatch,
    useResolvedPath,
    useSearchParams,
} from "react-router-dom";
import { usePageConfig } from "./hooks/usePageConfig";

export default function BrandingNavbar(props) {
    const pageConfig = usePageConfig();
    let { items } = props;
    items = getArray(items);
    const [searchParams] = useSearchParams();
    const cat = searchParams.get('cat');
    return <>
        <header id="masthead" className="site-header" role="banner">
            <div className="site-branding container" style={pageConfig.nav?.styles?.['site-branding']?.css}>
                <div className="row" style={{position: "relative"}}>
                    <div className="col-md-4 logo-glow-outer">
                        <div className="buddha-glow"></div>
                        <div className="buddha-img"></div>
                        <div className="logo-keeper">
                            <div className="lion-head" alt="praveenranjan.com website logo" />
                        </div>
                    </div>
                    <div className="col-md-8 head-right">
                        <div className="content">
                            <div className="row head-right-in">
                                <div className="col-md-12 custom-logo-container">
                                    <Link to="/" hidden={pageConfig.nav['custom-logo']?.hidden} className="custom-logo-link" rel="home" aria-current="page">
                                        {/*<img src="/assets/img/logo.png" className="custom-logo" alt="praveenranjan.com website logo" />*/}
                                    </Link>
                                </div>
                                <div className="col-md-12 nav-container" style={{padding: "0px"}}>
                                    <nav id="site-navigation" className="main-navigation" role="navigation">
                                        <div>
                                            <div className="col-md-12">
                                                <div className="menu-primary-menu-container">
                                                    <ul id="primary-menu" className="d-none d-lg-table menu nav-menu">
                                                        {items.map(item => <CustomLi key={item.key} item={item} options={{ cat }} />)}
                                                    </ul>
                                                    <div className="d-lg-none">
                                                        <ul id="primary-menu" className="menu nav-menu">
                                                            {items.map((item, i) => i <= 5 && !isAbsent(item) && <CustomLi key={item.key} item={item} options={{ cat }} />)}
                                                        </ul>
                                                        <ul id="primary-menu" className="menu nav-menu menu-2">
                                                            {items.map((item, i) => i > 5 && !isAbsent(item) && <CustomLi key={item.key} item={item} options={{ cat }} />)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="google_translate_wrap">
                    <span className="gt_header">Translate</span>
                        <div id="google_translate_element"></div>
                    </div>
                </div>
            </div>

        </header>
    </>;
}

function CustomLi({ item, options = {} }) {
    const resolved = useResolvedPath(item.href);
    const match = useMatch({ path: resolved.pathname, end: true });
    if (Object.hasOwn(item, 'loading')) {
        if (isAbsent(item)) {
            return <></>;
        }
    }
    const itemTitle = getTitle(item);
    if (match) {
        return <li className='menu-item menu-item-type-custom menu-item-object-custom current-menu-item'>
            <a aria-current="page">{itemTitle}</a>
            {options.cat && <span className="badge badge-tag cat-info">{'#' + postHelper.ucfirst(options.cat)}</span>}
        </li>;
    } else {
        let link = <Link to={item.href}> {itemTitle} </Link>;
        if (item.href?.indexOf("http://") === 0 || item.href?.indexOf("https://") === 0) {
            link = <a href={item.href} target="_blank">{itemTitle}</a>;
        }
        return <li className='menu-item menu-item-type-custom menu-item-object-custom'>
            {link}
        </li>;
    }
}

function isAbsent(item) {
    return !item.loading && item.value && !item.value.length;
}
