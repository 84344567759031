import { useEffect } from "react";

const useScript = (src) => {
        const url = window.location.pathname.split('/').pop();
        useEffect(() => {
        const script = document.createElement('script');

        script.src = src;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [src, url]);
}

export { useScript };