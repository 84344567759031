import QuotePost from "./QuotePost";
import WriterPost from "./WriterPost";
import NewspaperPost from "../NewspaperPost";
import { Apt } from "../Loader";
import { value, Iter, Widget } from "./Widget";
import BlogPost from "./BlogPost";
import { shouldShow, filterEntity, filterEntities } from "../helper";
import { Link, useSearchParams } from "react-router-dom";
import { useScript } from "../../hooks/useScript";
import OfferPost from "./OfferPost";


export default function Sitebody({ data, options = {} }) {
    useScript("https://static.addtoany.com/menu/page.js");
    const [searchParams] = useSearchParams();
    let { page, entityRef } = options;
    let componentProps = {};
    if (entityRef) {
        componentProps = { colMd: 12, colLg: 12, colSm: 12, entityPage: true };
        data = filterEntity(entityRef, { page, data });
    } else {
        const cat = searchParams.get('cat');
        if (cat) {
            data = filterEntities(cat, { page, data });
        }
    }
    const postsState = data.posts;
    let posts = value(postsState);
    let postsWidget;
    if (!page) {
        //var visib = postsState.homeItems ?? config.defaultHomepageItems;
        posts = posts.slice(3, 7);
        postsWidget = <PostWidget postsState={postsState} posts={posts} />;
    } else if (shouldShow('posts', page, postsState)) {
        postsWidget = <Widget id="newspaper_x_widget_posts_c-2" page={page} title="Posts" rowClass="newspaper-x-layout-c-row" iter={{ state: postsState, Component: BlogPost, componentProps }} />
    }
    const quotesState = data.quotes;
    const others = Object.keys(data).filter(k => ['posts', 'quotes', 'offers'].indexOf(k) === -1 && shouldShow(k, page));
    return (
        <div className="container site-content">
            <div className="row">
                <div className="col-md-12 newspaper-x-content newspaper-x-with-sidebar">
                    {postsWidget}
                    {shouldShow('quotes', page, quotesState) && <Widget page={page} id="newspaper_x_widget_posts_c-2" title="Quotes" rowClass="newspaper-x-layout-c-row" iter={{ state: quotesState, Component: QuotePost, componentProps }} />}
                    {
                        others.map((other, i) =>
                            shouldShow(other, page, data[other]) && <Widget id="newspaper_x_widget_posts_b-3" key={i} page={page} rowClass="newspaper-x-layout-b-row" iter={{ state: data[other], Component: WriterPost, componentProps }} />
                        )
                    }
                    {shouldShow('offers', page, data.offers) && <Widget page={page} id="newspaper_x_widget_posts_c-2" title="Quotes" rowClass="newspaper-x-layout-c-row" iter={{ state: data.offers, Component: OfferPost, componentProps }} />}
                </div>
            </div>
        </div>
    );
}
function PostWidget({ postsState, posts }) {
    const viewMoreButton = <Link to={`/posts`} className="btn btn-blog ">View All »</Link>;
    return <div id="newspaper_x_widget_posts_a-4" className="widget newspaper_x_widgets">
        <div className="site-content newspaper-spacer-a">
            <div className="row">
                <div className="col-md-12">
                    <div className="newspaper-x-recent-posts newspaper-x-recent-posts-a">
                        <Apt state={postsState}>
                            <Iter posts={posts} Component={NewspaperPost} i={3} top/>
                            {viewMoreButton}
                        </Apt>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

