import config from "../config.json";
import { useLocation } from "react-router-dom";

export function usePageConfig() {
    const defaultConfig = config.default;
    let location = useLocation();
    const page = location.pathname.substring(1);
    let pageConfig = {};
    try { pageConfig = config.items[page]; } catch (e) { }
    pageConfig = { ...defaultConfig, ...pageConfig };
    return pageConfig;
}
