import "./Offer.css";

export default function OfferPost(props) {
    return (
        <div><div className="coupon">
            <div className="coupon-container">
                <h3>
                    <img src="https://res.cloudinary.com/prk/image/upload//offers/Samsung_Logo.jpg" alt="Samsung Logo" />
                </h3>
            </div>
            <img src="https://images.samsung.com/is/image/samsung/assets/in/2302/home/HOME_DM3_KV_Main-KV_1440x640_pc_preivew.jpg" alt="Avatar" style={{ width: "100%" }} />
            <div className="coupon-container" style={{ backgroundColor: "white" }}>
                <h2><b>Upto 5% OFF YOUR PURCHASE</b></h2>
                <p>Use my Samsung Referral code to get flat 5% off on multiple Samsung Products like the Galaxy Smartphones and Laptops etc. (Yes, Even Galaxy Books)</p>
            </div>
            <div className="coupon-container">
                <p onClick={copyToClipboard} style={{ margin: "1em", display: "flex" }}>
                    <span style={{ cursor: "pointer" }}>Use Referral Code as Coupon (Click to copy): </span>
                    <span><span className="promo">REFPBBGKK8AG</span></span>
                    <button href="https://www.samsung.com/in/" className="greenlink" style={{ borderColor: "green", padding: "3px 2px", marginLeft: "2px" }}>Visit Website</button>
                </p>
            </div>
        </div>
        </div>
    );
}

function copyToClipboard(e) {
    // Get the text field
    // Copy the text inside the text field
    navigator.clipboard.writeText("REFPBBGKK8AG").then(_ => {
        // Alert the copied text
        alert("Copied to clipboard!");

        const href = e.target.getAttribute('href');
        if(href) {
            window.location.href = href;
        }
    });
}

