import { Link } from "react-router-dom";
import { postHelper } from "./helper";
import EmbeddedPost from "./Sitebody/EmbeddedPost";
import { CategoryTags, LikeButton } from "./Sitebody/Widget";

export default function NewspaperPost(props) {
    const post = postHelper(props.post);

    if(post.embedCode) {
        return <li className="blazy b-loaded" id={"newspaper-x-recent-post-" + (props.i)}>
            <EmbeddedPost {...props} colSm="12" colMd="12"/>
        </li>;
    }
    return <li className="blazy b-loaded" id={"newspaper-x-recent-post-" + (props.i)} style={{ backgroundImage: `url("${post.banner()}")` }}>
        <div className="newspaper-x-post-info">
            <H1Or6 i={props.i}>
                <Link
                    to={post.detailLink()}>
                    {post.headline()}
                </Link>
            </H1Or6>
            <CategoryTags post={post}/>
            <span className="newspaper-x-date">{post.dateFormatted()}</span>
        </div>
    </li>;

    function H1Or6(props) {
        const { i } = props;
        let Elt = 'h6';
        if (i === 0) { Elt = 'h1'; }
        return <Elt>{props.children}</Elt>;
    }
}

