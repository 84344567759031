export default function Footer(props) {
    const { me } = props;
    return (
        <footer id="colophon" className="site-footer" role="contentinfo">
            <div className="widgets-area">
                <div className="container" style={{ color: 'white' }}>
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div id="text-2" className="widget widget_text">
                                <h3 style={{ color: 'white' }} className="widget-title about-title">About</h3>
                                <div className="textwidget">
                                    <p>
                                        Hi! This is me Praveen. This website is a collection of most of my writtings, quotes, saved stuffs etc.
                                    </p>
                                    <p>
                                        If you wish to contribute, kindly contact me. 😊
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                        </div>
                        <div className="col-md-3 col-sm-6">
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div id="newspaper_x_widget_contact_us-2"
                                className="widget widget_text">
                                <h3 className="widget-title">Contact</h3>
                                <div className="textwidget contact-widget">
                                    <div>
                                        <span>Email:</span>
                                        <a style={{ color: 'indianred', fontStyle: 'italic' }} href={"mailto:" + me.email}> {me.email}</a>
                                    </div>
                                    <div><span>Address:</span> {me.city}, {me.country}</div>
                                    <div className="author-social-menu">
                                        <ul id="social-contact" className="menu">
                                            <li
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-111">
                                                <a target="blank" href={me.facebook}><span>Facebook</span></a>
                                            </li>
                                            <li
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-113">
                                                <a target="blank" href={me.twitter}><span>Twitter</span></a>
                                            </li>
                                            <li
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-114">
                                                <a target="blank" href={me.youtube}><span>Youtube</span></a>
                                            </li>
                                            <li
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-114">
                                                <a target="blank" href={me.yourquote}><span>Yourquote</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="back-to-top-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <a href="#" id="back-to-top" className="back-to-top-is-visible">
                                <span>Go Up</span>
                                <i className="fa fa-angle-up" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-info">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            © Praveen Ranjan Keshri
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}