import config from "../config.json";
import JSON5 from "json5";
import { emitCustomEvent } from 'react-custom-events';

export function apiHelper() {
  const results = {};
  const promises = [];
  return {
    init() {
      const configItems = Object.keys(config.items);
      for (let i = 0; i < configItems.length; i++) {
        const configItem = configItems[i];
        ((configItem, i) => {
          const c = config.items[configItem];
          const url = c.url;
          const resultConfigItem = results[configItem] = results[configItem] || {
            key: configItem,
            config: c
          };
          if (c.href || c.value) {
            resultConfigItem.value = c.value;
            return;
          }
          resultConfigItem.loading = true;
          promises.push(makePromise(url, resultConfigItem));
        })(configItem, i);
      }
      return results;
    },
    exec() {
      let p;
      // eslint-disable-next-line no-cond-assign
      while(p = promises.shift()) p();
    }
  }



  function makePromise(url, resultConfigItem) {
    return () => {
      new Promise((resolve, reject) => {
        if(!url) {
          resultConfigItem.loading = false;
          resultConfigItem.value = [];
          emitCustomEvent('my-event', results);
          return;
        } else if(window?.__injected_config?.[resultConfigItem.key]) {
          const w = window?.__injected_config?.[resultConfigItem.key];
          resultConfigItem.loading = false;
          resultConfigItem.value = w;
          emitCustomEvent('my-event', results);
          return;
        }
        url = urlEx(url);
        fetch(url).then(r => r.text()).then(data => {
          resultConfigItem.loading = false;
          resultConfigItem.value = JSON5.parse(data);
          emitCustomEvent('my-event', results);
        }).catch(e => {
          resultConfigItem.loading = false;
          resultConfigItem.error = e;
          emitCustomEvent('my-event', results);
        });
      });
    }
  }
}

let x;
function urlEx(url) {
  if(!x) {
    let d = new Date();
    let h = d.getHours();
    h = parseInt(h/4)
    d.setHours(h, 0, 0, 0);
    x = `${d.getFullYear()}${d.getMonth()+1}${d.getDate()}-${h}`
  }
  if(url.indexOf('?') === -1) {
    url += '?';
  } else {
    url += '&';
  }
  url += `x=${x}`;
  return url;
}