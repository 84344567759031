import config from "../config.json";
const sepSrcId = '_';
/**
 * @param {*} post 
 *      post.detailLink
 *      post.banner
 *      post.headline
 *      post.linkToCategory
 *      post.category
 *      post.dateFormatted
 *      post.detailShort
 *      post.src
 *      post.embedCode
 *      post.type
 */
export function postHelper(post) {
    const cache = {};
    const handlers = {
        dateFormatted: () => {
            return post.date
        },
        detailShort: () => {
            return post.text.substr(0, config.blogPost.shortTitleMax);
        },
        detailLink: (noEncode = false) => {
            if (!cache.detailLink) {
                let x = `${permalink(post)}-${post.src}${sepSrcId}${post.srcId}`;
                if (!noEncode) {
                    x = encodeURIComponent(x);
                }
                cache.detailLink = post.href ?? `/${post.type}s/${x}`;
            }
            return cache.detailLink;
        },
        shortLink: () => {
            if (!cache.shortLink) {
                let x = `/${post.type[0]}-${post.src}${sepSrcId}${post.srcId}`;
                cache.shortLink = x;
            }
            return cache.shortLink;
        },
        banner: () => {
            let img = post.img;
            img = img.split('$CDN').join(config.cdn.img);
            return img;
        },
        headline: () => {
            return post.headline
        },
        author: {
            link: () => {

            },
            name: () => {

            }
        },
        category: () => {
            return post.categories[0]
        },
        linkToCategory: (cat) => {
            return `/${post.type}s?cat=${encodeURIComponent(cat)}`
        }
    };

    return new Proxy(post, {
        get: (target, key) => handlers[key] ?? post[key]
    });
}

postHelper.ucfirst = (str) => {
    if (str) {
        return str[0].toUpperCase() + str.substr(1);
    }
}

function permalink(post) {
    let tmp = browserTitle(post);
    tmp = tmp.toLowerCase();
    return tmp.split(/\s/).join('-');
}

export function browserTitle(post) {
    const permalinkMax = config.permalinkMax;
    let t = post.headline ?? post.text;
    let tmp = t.substr(0, permalinkMax);
    if (t[permalinkMax] && !t[permalinkMax].match(/\s/)) {
        tmp += t.substr(permalinkMax).split(/\s/)[0];
    }
    return tmp;
}

export function embeddedHelper(post) {
    const handlers = {
    }

    return new Proxy(post, {
        get: (target, key) => post[key] ?? handlers[key]
    });
}

export function getTitle(item, defaultTitle) {
    let title = item.config?.title ?? item.key ?? defaultTitle;
    if (title) {
        if (title.html) {
            title = <span dangerouslySetInnerHTML={{ __html: title.html }}></span>
        } else {
            title = title[0].toUpperCase() + title.substr(1);
        }
    }
    return title;
}

export function getArray(items) {
    return Object.keys(items).map(i => {
        const item = items[i];
        if (!item.key) { item.key = i; }
        item.href = item.href ?? item.config?.href ?? `/${i}`;
        return item;
    });
}

export function shouldShow(widget, page, state) {
    if (!page) {
        if (state?.value?.length)
            return true;
        else
            return false;
    } else if (page === widget) {
        return true;
    }
}

export function filterEntity(entityRef, { page, data }) {
    let tmp = entityRef.split('-').pop().split(sepSrcId);
    const [entitySrc, entitySrcId] = tmp;
    const retData = {};
    Object.keys(data).forEach(k => {
        const datum = Object.assign({}, data[k]);
        retData[k] = datum;
        if (k === page && datum.value) {
            datum.value.some(entity => {
                if (entity.src === entitySrc && entity.srcId === entitySrcId) {
                    datum.value = [entity];
                    return true;
                }
                return false;
            });
        }
    });

    return retData;
}

export function filterEntities(cat, { page, data }) {
    const retData = {};
    Object.keys(data).forEach(k => {
        const datum = Object.assign({}, data[k]);
        retData[k] = datum;
        if (k === page && datum.value) {
            const newValue = [];
            datum.value.forEach(entity => {
                if (entity.categories && entity.categories.includes(cat)) {
                    newValue.push(entity);
                }
            });
            datum.value = newValue;
        }
    });

    return retData;
}

export const lim = (x, l = x) => {
    return x < l ? x : l;
}
